import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../../layouts/DefaultLayout";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Mouldings">
    <div className="container my-5">???</div>
  </DefaultLayout>
);

export default Page;
